import { ref, computed,shallowReactive } from 'vue'
import { getOptimizeLink } from './ccclink'

export const isBrowser = typeof window !== 'undefined'

const SHOW_COMPONENT = 'FLOOR_COMPONENT'

function getSaInfo(navCate, item, type) {
  let saInfo = {}

  const { categoryName, index, cateLinks = {} } = item
  if(type === 'top'){
    saInfo = getOptimizeLink({
      data: item,
      cateLinks,
      secondCateIndex: index,
      firstCateIndex: index,
      secondCateName: categoryName || '',
      currentOneCategory:  categoryName || '',
    })
  }else if(type === 'two' || type === 'three'){
    if(item.children?.length){
      item.saInfo = getOptimizeLink({
        data: item,
        cateLinks:{},
        firstCateIndex: navCate.index,
        secondCateIndex: navCate.index,
        secondCateName: navCate.categoryName || '',
        thirdCateName: item.categoryName || '',
        thirdCateIndex: index,
        currentOneCategory:  navCate.categoryName || '',
      })

      for (let i = 0; i < item.children.length; i++) {
        const child = item.children[i]
        let topSaInfo = getOptimizeLink({
          data: child,
          firstCateIndex: navCate?.index,
          secondCateName: navCate.categoryName,
          secondCateIndex: navCate.index,
          thirdCateName: item.categoryName || '',
          thirdCateIndex: item.index,
          forthCateName: child.categoryName,
          forthCateIndex: i,
          currentOneCategory: navCate?.categoryName,
          cateLinks,
        })
        child.index = i
        child.saInfo = topSaInfo //Object.assign({},saParams)
      }
    }
  }
  return saInfo
}

function formateItem(navCate, item, index, type) {
  const { cateId, child, props } = item
  const { categoryName, categoryLanguage, categoryType, categoryId, autoRecommend } = props?.metaData || {}
  const children = []
  if (child?.[0]?.componentKey === 'CATEGORY_COMPONENT' && child[0]?.props?.items?.length) {
    children.push(...child[0].props.items)
  }
  const newItem = { cateId, index, categoryName, categoryLanguage, categoryType, categoryId, autoRecommend, children }
  getSaInfo(navCate, newItem, type)
  return newItem
}

/**
 * @description 数据精简化tab模板
 * @param {*} cates
 * @returns
 */
function formatNavs(navs, abtest) {
  const newNavs = []
  if (navs && navs.length > 0) {
    navs.forEach((item, index) => {
      const { id, name,enName, firstFloorContent, navNodeId, cateLinks } = item
      const metaData = firstFloorContent?.props?.metaData || {}
      const { categoryName, categoryLanguage, categoryType, categoryId, autoRecommend } = metaData
      const newItem = {
        abtest,
        id,
        name,
        enName,
        navNodeId,
        index:index,
        categoryName,
        categoryLanguage,
        categoryType,
        categoryId,
        autoRecommend,
        cateLinks,
      }
      newItem.saInfo = getSaInfo(newItem, newItem, 'top')
      newNavs.push(newItem)
    })
  }
  return newNavs
}

/**
 * @description 数据精简化下拉导航模板
 * @param {*} cates
 * @returns
 */
function formatRightCate(navCate, cates) {
  const rightCate = { two: [], three: [] }
  if (cates && cates.length > 0) {
    const two = []
    const three = []
    cates
      .forEach((item, index) => {
        if (index === 0) {
          two.push(formateItem(navCate, item, index, 'two'))
        } else {
          three.push(formateItem(navCate, item, index, 'three'))
        }
      })
    rightCate.two = two
    rightCate.three = three
  }
  return rightCate
}

const RIGHT_CACHES = shallowReactive({})

const TASK_MANAGER = {
  TASK_CACHE: {},
  TASK_QUEN: [],
  timer: null,
  addTask(channelId, item, apis, callback){
    const navNodeId = item.navNodeId
    const key = `${channelId}_${navNodeId}`
    let task = this.TASK_CACHE[key]
    if(!task){
      task = new Promise((resolve,reject) => {
        apis.getLapTopRight(channelId, navNodeId).then(res => {
          const list = (res.info.contents || []).filter(item => item.componentKey === SHOW_COMPONENT)
          typeof callback === 'function' && callback(list)
          RIGHT_CACHES[navNodeId] = formatRightCate(item, list || [])
          resolve(RIGHT_CACHES[navNodeId])
        }).catch(err => reject(err))
      })
      this.TASK_CACHE[key] = task
    }
    this.TASK_QUEN.push(task)
  },
  run(fn){
    if(this.timer) clearTimeout(this.timer)
    this.timer = setTimeout(async () => {
      // console.log('ccate',TASK_MANAGER.TASK_QUEN);
      if(typeof fn === 'function'){
        try {
          const next = () => Promise.all(this.TASK_QUEN)
          await fn(next)
          this.TASK_QUEN = []
        } catch (error) {
          console.log(error)
        }
      }
    }, 80)
  },
  clear(){
    this.TASK_QUEN = []
  },
}

export function useData(apis, props, emits) {
  let abtest = ''
  if(isBrowser && typeof props.getabtest === 'function'){
    abtest = props.getabtest({ posKeys: 'PCAlltabCategory' })?.sa
  }
  const cateNavs = ref(formatNavs(props?.fsData, abtest))
  // cateNavs.value.splice(2,0, {navNodeId:'549748', categoryLanguage:'test node data'})
  // cateNavs.value.splice(2,0, {navNodeId:'common:205791:shein:pl_pl:ios', categoryLanguage:'test error data', name:'left name'})
  const channelId = props.channelId || 10
  const curNavCate = ref({})
  const curNavId = ref('')
  const rightCate = computed(() => {
    const navId = curNavId.value
    if(navId && RIGHT_CACHES[navId]){
      return RIGHT_CACHES[navId]
    }
    return {two:[],three:[]}
  })
  const loading = ref(true)
  let navIds = []
  const setNavId = (id) => {
    const lastId = navIds[navIds.length - 1]
    // console.log(navIds)
    // console.log('lastId=%s,id=%s',lastId,id);
    if(lastId && lastId !== id){
      curNavId.value = lastId
    }else{
      curNavId.value = id
    }
    navIds = []
  }
  const getCateGoryData = async function (item) {
    const navNodeId = item.navNodeId
    curNavCate.value = item
    let cates = RIGHT_CACHES[navNodeId]
    if(!cates){
      navIds.push(navNodeId)
      TASK_MANAGER.addTask(channelId, item, apis, (list) => {
        if(!list.length){
          const index = cateNavs.value.findIndex(item => item.navNodeId === navNodeId)
          cateNavs.value.splice(index, 1)
          emits('noData', navNodeId)
        }
      })
      TASK_MANAGER.run(async next => {
        loading.value = true
        await next()
        // curNavId.value = navNodeId
        setNavId(navNodeId)
        loading.value = false
      })
    }else{
      curNavId.value = navNodeId
    }
    // console.log('ccate', navNodeId);
    return cates
  }
  return {
    channelId,
    cateNavs,
    rightCate,
    curNavCate,
    loading,
    getCateGoryData,
  }
}
