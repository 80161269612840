let SaPageInfoCache = {}

const robotLinkCommon = (name = 'navigationBar', other = '') => {
  const page_name = SaPageInfoCache?.page_name || 'page_others'
  if (page_name === 'page_order_list') name = 'orderListPage'
  if (page_name === 'page_order_detail') name = 'orderDetailPage'
  if (page_name === 'page_contact_us') name = 'supportPage'
  if (page_name === 'page_order_track') name = 'trackPage'
  return `/robot?page=${name}&page_name=${page_name}${other}`
}

const jumpToRobot = (name = 'navigationBar', options) => {
  let params = ''
  const robotStoreData = window?.gbCommonInfo?.robotStoreData || null
  if (robotStoreData) {
    let { storeCode = '', pageName = '', storeType, business_model } = robotStoreData
    if (name != 'NoLoginPage') name = pageName ? pageName : 'storePage'
    params = `&storecode=${storeCode}&business=${typeof business_model != 'undefined' ? business_model : (storeType == 1 ? 1 : 0)}`
  }

  if (options?.billno) params += `&billno=${options?.billno}`

  window.open(robotLinkCommon(name, params))
}

export const getOrderJumpInfo = (SaPageInfo) => {
  SaPageInfoCache = SaPageInfo
  let { page_name, page_param = {} } = SaPageInfoCache
  let isOrderModule = ['page_order_list', 'page_order_detail', 'page_order_track']?.includes(page_name)
  if (isOrderModule) {
    let textPageMap = {
      'page_order_list': {
        'name': 'orderListPage',
        'billno': window?.ORDER_LIST?.orders?.[0]?.billno || '',
      },
      'page_order_detail': {
        'name': 'orderDetailPage',
        'billno': window?.ORDER_DETAIL?.order?.billno || '',
      },
      'page_order_track': {
        'name': 'trackPage',
        'billno': page_param?.order_no || '',
      },
    }
    let { name, billno } = textPageMap?.[page_name] || {}
    jumpToRobot(name, { billno })
  } else {
    jumpToRobot()
  }
}
