import type { DS_Category, C_SwichLangCurrency } from '../../../types'

// TODO write your apis
export const getNavTabs: DS_Category.APIS_THIS['getNavTabs'] = async function (params) {
  const result = await this.$schttp<Array<object>>({
    url: 'ccc/nav/tab_cate',
    params,
  })
  return result.data
}

export const getLeftContent: DS_Category.APIS_THIS['getLeftContent'] = async function (params) {
  const result = await this.$schttp<Array<object>>({
    url: 'ccc/nav/left',
    params,
  })
  return result.data
}

export const getRightContent: DS_Category.APIS_THIS['getRightContent'] = async function (params) {
  const result = await this.$schttp<Array<object>>({
    url: 'ccc/nav/right',
    params,
  })
  return result.data
}


export const getRightContentBatch: DS_Category.APIS_THIS['getRightContentBatch'] = async function (params) {
  const result = await this.$schttp<Array<object>>({
    url: 'ccc/nav/right/batch',
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'POST',
    data: params,
  })
  return result.data
}

/**
 * @description 获取桌面端右侧导航数据
 * @param params 
 * @returns 
 */
export const getLapTopRight: DS_Category.APIS_THIS['getLapTopRight'] = async function (channelId, id) {
  const result = await this.$schttp<Array<object>>({
    url: '/ccc/nav/right',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    params: {
      channelType: String(channelId),
      id
    }
  })
  return result.data
}

export const getUnReadNum: DS_Category.APIS_THIS['getUnReadNum'] = async function () {
  const result = await this.$schttp<Array<object>>({
    url: 'user/cschat/get_unread_count',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    // data: params,
  })
  return result.data
}

export const getLanguage: DS_Category.APIS_THIS['getLanguage'] = async function () {
  const languageKeys = [
    'SHEIN_KEY_PWA_14979',
    'SHEIN_KEY_PWA_14980',
    'SHEIN_KEY_PWA_14981',
    'SHEIN_KEY_PWA_15668',
    'SHEIN_KEY_PWA_15669',
    'SHEIN_KEY_PWA_16210',
    'SHEIN_KEY_PWA_17167',
    'SHEIN_KEY_PWA_17501',
    'SHEIN_KEY_PWA_19007',
    'SHEIN_KEY_PWA_18057',
    'SHEIN_KEY_PWA_18362',
    'SHEIN_KEY_PWA_15497',
    'SHEIN_KEY_PWA_16036',
  ]
  const result = await this.$schttp<Array<object>>({
    url: '/system/configs/multi_language',
    method: 'POST',
    headers:{
      'Content-Type': 'application/ison'
    },
    data: {languageKeys},
  })
  return result.data
}

// 获取多语言列表
export const getlangs: DS_Category.APIS_THIS['getlangs'] = async function () {
  const result = await this.$schttp<C_SwichLangCurrency.LANG_INFO>({
    url: '/user/language/list',
  })
  return result.data
}

// 语种切换
export const setlang: DS_Category.APIS_THIS['setlang'] = async function (new_lang: String) {
  const result = await this.$schttp<C_SwichLangCurrency.BASE_RESULT>({
    url: '/user/language/change',
    params: { new_lang },
  })
  return result.data
}

// 获取币种列表
export const getCurrencys: DS_Category.APIS_THIS['getCurrencys'] = async function () {
  const result = await this.$schttp<C_SwichLangCurrency.CURRENCY_INFO>({
    url: '/user/currency/list',
  })
  return result.data
}

// 币种切换
export const setCurrency: DS_Category.APIS_THIS['setCurrency'] = async function (currency: String) {
  const result = await this.$schttp<C_SwichLangCurrency.BASE_RESULT>({
    url: '/user/currency/change',
    params: { currency },
  })
  return result.data
}
