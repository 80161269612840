<template>
  <span class="bsc-cart-discount">
    <span
      v-if="formatCartNum"
      class="bsc-cart-discount__num"
    >
      {{ formatCartNum }}
    </span>

    <span
      ref="refCartPrice"
      class="bsc-cart-discount__price"
    >
      {{ cartPrice }}
    </span>

    <span
      ref="refTagWrap"
      class="bsc-cart-discount__tag-wrap"
      :style="tagWrapStyle"
    >
      <transition
        :name="transitionName"
        @after-enter="afterEnter"
      >
        <span
          v-if="type === 'freeshipping'"
          class="bsc-cart-discount__tag bsc-cart-discount__tag-free"
          :style="tagStyle"
        >
          <FreeshippingSvg />
          <span class="bsc-cart-discount__tag-text">Free</span>
        </span>
        <CartDiscountSave
          v-else-if="type === 'save'"
          :style="tagStyle"
          :text="text"
        />
        <span
          v-else-if="type === 'gift'"
          class="bsc-cart-discount__tag bsc-cart-discount__tag-gift"
          :style="tagStyle"
        >
          <GiftSvg />
          <span class="bsc-cart-discount__tag-text">Gift</span>
        </span>
      </transition>
    </span>
  </span>

  <Teleport to="body">
    <CartTip
      v-if="cartTipShow"
      ref="refCartTip"
      @after-enter="cartTipAfterEnter"
      @click="handleCartTipClick"
      @expose="handleCartTipExpose"
    />
  </Teleport>

  <CartDiscountSave
    ref="refSaveTag"
    :text="cloneText"
    style="position:fixed;top:-9999px;z-index:-9999;"
  />
</template>

<script name="BCartDiscount" setup lang="ts">
import { ref, computed, onMounted, nextTick, inject } from 'vue'
import { cartTagTip } from '@shein-aidc/bs-sdk-cart-tag-tip'
import type { TagTip } from '@shein-aidc/bs-sdk-cart-tag-tip'
import CartDiscountSave from './cart-discount-save.vue'
import FreeshippingSvg from '../svgs/freeshipping.vue'
import GiftSvg from '../svgs/gift.vue'
import CartTip from './cart-tip.vue'
import { getCloneDomWithText, getClonedDomWidth } from '../utils/element.ts'

import type { C_HeaderCart } from '../../../types'

const emit = defineEmits(['click-tip'])

const triggerNotice: any = inject('triggerNotice')

const props = defineProps<{
  cartNum: number
}>()

const formatCartNum = computed(() => {
  if (props.cartNum > 999) {
    return '999+'
  }
  return props.cartNum
})

const useCartTip = () => {
  const refCartTip = ref(null)
  const show = ref(false)
  const open = async (...args) => {
    show.value = true
    await nextTick()
    refCartTip.value?.open(...args)
  }
  const clear = () => {
    refCartTip.value?.clear()
  }
  const afterEnter = () => {
    show.value = false
  }
  return {
    refCartTip,
    show,
    open,
    clear,
    afterEnter,
  }
}
const { refCartTip, show: cartTipShow, open: cartTipOpen, clear: cartTipClear, afterEnter: cartTipAfterEnter } = useCartTip()

const refTagWrap = ref(null)
const useStyle = ({ refTagWrap }) => {
  const tagWrapStyle = ref({})
  const tagStyle = ref({})
  const setStyle = (step) => {
    if (!refTagWrap.value) return

    if (step.type === 'default') {
      tagWrapStyle.value = {
        height: 0,
        width: '100%',
        overflow: 'hidden',
      }
    } else {
      const rect = refTagWrap.value.getBoundingClientRect()
      if (rect.height) {
        tagWrapStyle.value = {
          height: `${rect.height}px`,
          width: `${rect.width}px`,
        }
        tagStyle.value = {
          position: 'absolute',
        }
      }
    }
  }
  const clearStyle = () => {
    tagWrapStyle.value = {}
    tagStyle.value = {}
  }
  return {
    tagWrapStyle,
    tagStyle,
    setStyle,
    clearStyle,
  }
}
const {
  tagWrapStyle,
  tagStyle,
  setStyle,
  clearStyle,
} = useStyle({ refTagWrap })

const cartPrice = ref('')
const transitionName = ref('')
const type = ref('')
const text = ref<boolean | string | null>('')

let excludeScroll = false
let nextStep: TagTip.Step | null = null
let timer: ReturnType<typeof setTimeout> | null = null
let callback: TagTip.Config['callback']

const refCartPrice = ref()
const refSaveTag = ref()
const cloneText = ref('')

const getTextByStep = async step => {
  if (step.type === 'save') {
    cloneText.value = step.tagText
    await nextTick()
    const priceDom = refCartPrice.value
    const priceDomWidth = priceDom.offsetWidth
    const tagDom = getCloneDomWithText(refSaveTag.value.$el)
    const tagDomWidth = getClonedDomWidth(tagDom)
    if (tagDomWidth - priceDomWidth < 16) return step.tagText
  }
  return ''
}

const setTag = async (steps: TagTip.Step[], config: TagTip.Config) => {
  const [step1 = null, step2 = null] = steps
  // TODO: step1.excludeScroll 放在 config 中
  if (step1?.excludeScroll) await sleep(300)
  excludeScroll = false

  clear()

  // 执行动画前，需要设置动画的必要样式
  setStyle?.(step1)

  nextTick(async () => {
    if (step1) {
      excludeScroll = step1.excludeScroll || false

      cartPrice.value = step1.totalPrice

      // 兼容旧的配置
      // @ts-ignore
      if (!step1.type || step1.type === 'default') {
        callback = config.callback
        type.value = ''
        text.value = ''
        transitionName.value = ''

        if (step1.noTips || !step1.tips) {
          // 没有动画 手动执行 afterEnter
          afterEnter()
        } else {
          nextTick(() => {
            const cfg: C_HeaderCart.TipOpenConfig = {
              reference: config.reference,
              placement: config.placement,
              offsetY: config.offsetY,
              callback: config.callback,
            }
            cartTipOpen(step1, cfg)
          })
        }
      } else {
        if (step1.noTips || !step1.tips) {
          callback = config.callback
          // 没有动画 手动执行 afterEnter
          if (type.value === step1.type) afterEnter()
        } else {
          nextTick(() => {
            const cfg: C_HeaderCart.TipOpenConfig = {
              reference: config.reference,
              placement: config.placement,
              offsetY: config.offsetY,
            }
            if (!step2) cfg.callback = config.callback

            cartTipOpen(step1, cfg)
          })
        }

        const tagText = await getTextByStep(step1)
        type.value = step1.type
        text.value = tagText
        nextStep = null
        transitionName.value = 'bsc-cart-discount__tag-fade'
      }
    }

    if (step2) {
      nextStep = step2
      timer = setTimeout(() => {
        callback = config.callback
        type.value = step2.type
        text.value = step2.tag
        nextStep = null
        transitionName.value = 'bsc-cart-discount__tag-zoom'
      }, 3000)
    }
  })
}

const afterEnter = () => {
  // 当前动画执行结束后清除动画的必要样式
  // 如果有 step2 还未执行，等 step2 动画执行完再清除样式
  if (!nextStep) clearStyle?.()

  callback?.()
  callback = undefined
}

// 打断动画
const clear = () => {
  if (excludeScroll) return
  transitionName.value = ''

  if (nextStep) {
    type.value = nextStep.type
    text.value = nextStep.tag
    nextStep = null
    clearTimeout()
  }

  cartTipClear()

  // 动画被打断不会执行 afterEnter
  afterEnter()
}

const clearTimeout = () => {
  if (timer) {
    window.clearTimeout(timer)
    timer = null
  }
}

const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

const init = async () => {
  const step = await cartTagTip.getCurrentTag()
  type.value = step.type
  text.value = await getTextByStep(step)
  cartPrice.value = step.totalPrice
}

const handleCartTipClick = async () => {
  const { available_point_tip: available_point, actual_point_tip: actual_point } = await cartTagTip.getExposeData()
  triggerNotice({
    id: 'click_cart_entrance_leftpopover.comp_header-cart',
    data: {
      result: 1,
      available_point,
      actual_point,
    },
  })

  emit('click-tip')
}
const handleCartTipExpose = async () => {
  const { available_point_tip: available_point, actual_point_tip: actual_point } = await cartTagTip.getExposeData()
  triggerNotice({
    id: 'expose_cart_entrance_popover.comp_header-cart',
    data: {
      scene_from: 'scene_from',
      available_point,
      actual_point,
    },
  })
}

onMounted(() => {
  init()
})

defineExpose({
  init,
  setTag,
  clear,
})
</script>

<style lang="less">
.bsc-cart-discount {
  position: relative;
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  &__num {
    position: absolute;
    top: 50%;
    right: calc(100% + 4px);
    transform: translateY(-120%);
    padding: 1px 2.5px;
    height: 14px;
    min-width: 14px;
    line-height: 10px;
    font-size: 10px;
    color: #fff;
    background: #f00;
    border-radius: 12px;
    border: 1px solid #fff;
  }

  &__price {
    line-height: 18px;
    font-size: 14px;
    font-weight: 700;
  }

  &__tag-wrap {
    font-size: 0;
  }

  &__tag {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 1px 4px;
    height: 14px;
    font-size: 10px;
    color: #fff;
    background: #fa6338;
    border-radius: 2px;
  }

  &__tag-free {
    padding: 1px 6px;
    background: #198055;
  }

  &__tag-save {
    white-space: nowrap;
  }

  &__tag-text {
    margin-left: 2px;
    line-height: 10px;
    color: #fff;
  }
}

@keyframes bsc-cart-discount__tag-fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes bsc-cart-discount__tag-zoom {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
    transform: scaleX(0.5);
  }
  100% {
    opacity: 1;
    transform: scaleX(1);
  }
}
.bsc-cart-discount__tag-zoom-enter-active { /* stylelint-disable-line */
  animation: bsc-cart-discount__tag-zoom 0.3s;
}
.bsc-cart-discount__tag-zoom-leave-active { /* stylelint-disable-line */
  animation: bsc-cart-discount__tag-zoom 0.3s reverse;
}
.bsc-cart-discount__tag-fade-enter-active { /* stylelint-disable-line */
  animation: bsc-cart-discount__tag-fade 0.3s;
}
.bsc-cart-discount__tag-fade-leave-active { /* stylelint-disable-line */
  animation: bsc-cart-discount__tag-fade 0.3s reverse;
}
</style>
