<template>
  <!-- ssr 占位 -->
  <div
    v-if="topBannerConfig?.src"
    class="bsc-top-banner-placeholder"
  >
    <img
      class="bsc-top-ssr-banner"
      :src="topBannerConfig?.src"
    />
  </div>
  <div
    v-if="topBannerContent && topBannerContent.length"
    class="bsc-publicbanner-config-top-banners not-fsp-element"
  >
    <div
      class="j-da-event-box bsc-publicbanner-topbanner-container"
    >
      <div
        class="j-config-top-banner config-top-banner"
        role="link"
        :tabindex="0"
      >
        <div class="config-wrap j-topbanner-fixer">
          <AppCcc
            :content="topBannerContent"
            :context="context"
            :style="{ paddingRight: `${paddingRight}` }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script name="BTopBanner" setup lang="ts">
import { ref, onMounted, onUnmounted, provide, nextTick } from 'vue'
import { debounce } from '@shein/common-function'
import { transfromAndCutImg } from './ccc/common/utils.js'
import AppCcc from './ccc/CccContainer.vue'
// 数据源：
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import type { AS_TopBanner, DS_TopBanner } from '../../types'
import { getSource } from '../../common/dataSource'

const appConfigs = useAppConfigs()
const apis = getSource({}, appConfigs)
const { RESOURCE_SDK } = appConfigs.$envs || {}

const props = defineProps<{
  fsData?: DS_TopBanner.FS_DATA
  dataSource?: DS_TopBanner.DataSource
  analysisSource?: AS_TopBanner.AnalysisSource
  topBannerConfig?: any
}>()

import { useAnalysis } from '../../common/analysisSource'
const analysisInstance = useAnalysis(props.analysisSource)
provide('analysisInstance', analysisInstance)

provide('cutImg', (imgUrl, designWidth, exp) => {
  const {
    deviceData = '',
    isSupportWeb = '',
    isSupprotCut = false,
    sceneMap,
  } = RESOURCE_SDK || {}

  const cutData = {
    deviceData,
    isSupportWebp: Boolean(isSupportWeb),
    isSupprotCut,
    imgUrl,
    designWidth: Number(designWidth),
    sceneMap,
    exp,
  }

  return transfromAndCutImg(cutData)
})

provide('baseImageMounted', () => {
  nextTick(() => {
    // csr 渲染后隐藏ssr占位
    const placeholderEle = document.querySelector('.bsc-top-banner-placeholder') as HTMLElement
    if (placeholderEle) {
      placeholderEle.style.display = 'none'
    }
  })
})

const context = appConfigs.$envs
const bannerHeight = ref(48)
const paddingRight = ref('')
const topBannerContent = ref([])

provide('getBannerHeight', () => bannerHeight.value)

const getTopBannerContent = async () => {
  const res = await apis.topBannerFetch()
  topBannerContent.value = res?.info?.content || []
}

const resizeHandle = () => {
  let widthWithoutScrollbar = document.documentElement.clientWidth
  const scrollbarWidth = window.innerWidth - widthWithoutScrollbar
  paddingRight.value = `${scrollbarWidth ? scrollbarWidth + 'px' : 'none'}`
  widthWithoutScrollbar = widthWithoutScrollbar - 1 // 解决页面稍宽出现底部滚动条
  if (widthWithoutScrollbar < 1446) {
    // 小于1446时，与首页内容对其，减首页内容边距后计算高度
    const imgWidth = widthWithoutScrollbar - 48 - 48
    bannerHeight.value = imgWidth / 1350 * 48
  } else {
    bannerHeight.value = 48
  }
}

onMounted(() => {
  getTopBannerContent()
  resizeHandle()
  window.addEventListener('resize', debounce({
    func: () => {
      resizeHandle()
    },
    wait: 200,
  }))
})

onUnmounted(() => {
  window.removeEventListener('resize', resizeHandle)
})
</script>

<style lang="less">
.bsc-top-banner-placeholder {
  min-height: 48px;
}
.bsc-top-ssr-banner {
    margin: auto;
    width: 100%;
    height: calc(48 * (100vw - 97px) / 1350);
    max-height: 48px;
    max-width: 2560px;
    object-fit: cover;
    display: block;
}
@media (min-width: 2560px) {
    .bsc-top-ssr-banner {
        object-fit: contain;
    }
}
.bsc-publicbanner-topbanner-container {
  position: relative;
  cursor: default;
  z-index: 701;
}
.bsc-publicbanner-config-top-banners {  /* stylelint-disable-line */
  .config-top-banner {
    cursor: pointer;
    .config-wrap {
      position: fixed;
      left: 0 /* rtl:ignore */;
      z-index: 400;
      width: 100vw;
      height: 48px;
      transition: transform 0.3s;
      overflow: hidden;
      background-color: #fff;
      box-sizing: content-box;
    }
    /* stylelint-disable-next-line no-invalid-double-slash-comments */
    .bsc-static-image,
    // 倒计时banner
    .bsc-banner-countdown {
      height: 100%;
      @media (min-width: 2560px) {
        // 大于2560，两侧留白
        /* stylelint-disable-next-line selector-max-specificity */
        .base-img__inner {
          object-fit: contain
        }
      }
    }
  }
  /* stylelint-disable-next-line selector-class-pattern */
  .j-config-top-banner.config-top-banner {
    display: block;
    width: 100%;
    height: 100%;
    height: 48px;
    transition: height 0.5s;
    background-color: #fff;
  }
  [tabindex='0'] {
    outline: 0;
  }
}
</style>
