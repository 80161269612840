export const customLinkDirective = () => {
  return {
    mounted(el, binding) {
      const onClick = event => {
        event.preventDefault() // 阻止默认行为
      }

      const onMouseDown = () => {
        const { getJumpFlag, beforeHook, saInfo } = binding.value || {}
        if (typeof beforeHook === 'function') {
          beforeHook()
        }
        let href = el.getAttribute('href')
        if (href) {
          const merge = href.indexOf('?') === -1 ? '?':'&'
          if(typeof getJumpFlag === 'function'){
            const arr = [`src_tab_page_id=${saInfo.src_tab_page_id}`]
            try {
              const cateFlag = getJumpFlag()
              Object.keys(cateFlag).forEach(key => {
                arr.push(`${key}=${cateFlag[key]}`)
              })
            } catch (error) {
              console.log(error)
            }
            href += `${merge}${arr.join('&')}`
          }
          window.location.href = href
        }
      }

      el.addEventListener('click', onClick)
      el.addEventListener('mousedown', onMouseDown)

      el._vueCustomLinkOnClick_ = onClick
      el._vueCustomLinkOnMouseDown_ = onMouseDown
    },
    beforeUnmount(el) {
      if (el._vueCustomLinkOnClick_) {
        el.removeEventListener('click', el._vueCustomLinkOnClick_)
      }
      if (el._vueCustomLinkOnMouseDown_) {
        el.removeEventListener('mouseup', el._vueCustomLinkOnMouseDown_)
      }
    },
  }
}
