<template>
  <span
    class="bsc-header-cart j-bsc-header-cart"
    @mouseenter="onMouseenter"
    @click="onClick"
  >
    <CartSvg
      ref="refHeaderCartSvg"
      class="bsc-header-cart__svg"
    />
    <template v-if="mode === 'normal'">
      <CartNum :num="cartNum" />
    </template>
    <template v-if="mode === 'incentive'">
      <CartDiscount
        ref="refCartDiscount"
        :cart-num="cartNum"
        @click-tip="onClickTip"
      />
    </template>
    <slot></slot>
  </span>
</template>

<script name="BHeaderCart" setup lang="ts">
import { ref, onMounted, onUnmounted, provide } from 'vue'
import {
  cartTagTip,
  registerCart,
  unregisterCart,
  useCartNum,
} from '@shein-aidc/bs-sdk-cart-tag-tip'
import CartSvg from './svgs/cart.vue'
import CartDiscount from './components/cart-discount.vue'
import CartNum from './components/cart-num.vue'

import type { TagTip } from '@shein-aidc/bs-sdk-cart-tag-tip'
import type { AS_HeaderCart, Placement } from '../../types'

const emit = defineEmits(['before-click', 'before-click-tip'])

// 数据源：
interface IProps {
  analysisSource?: AS_HeaderCart.PCAnalysisSource
  mode?: 'normal' | 'incentive'
  ariaLabel?: string
  placement?: Placement
  offsetY?: number
}
const props = withDefaults(defineProps<IProps>(), {
  mode: 'normal',
})
// 数据源：END

// 埋点源：
import { usePCAnalysis } from '../../common/analysisSource'
const { triggerNotice } = usePCAnalysis(props.analysisSource)

provide('triggerNotice', triggerNotice)
// 埋点源：END

const { cartNum } = useCartNum()

const onMouseenter = async () => {
  if (props.mode === 'incentive') {
    const { available_point, actual_point } = await cartTagTip.getExposeData()
    triggerNotice({
      id: 'expose_hover_cart_entrance.comp_header-cart',
      data: {
        result: 1,
        available_point,
        actual_point,
      },
    })
  } else {
    triggerNotice({
      id: 'expose_hover_cart_entrance.comp_header-cart',
      data: {
        result: 1,
        available_point: '-',
        actual_point: '-',
      },
    })
  }
}

const onClick = async () => {
  emit('before-click')

  if (props.mode === 'incentive') {
    const { available_point, actual_point } = await cartTagTip.getExposeData()
    triggerNotice({
      id: 'click_cart_entrance.comp_header-cart',
      data: {
        result: 1,
        available_point,
        actual_point,
      },
    })
  } else {
    triggerNotice({
      id: 'click_cart_entrance.comp_header-cart',
      data: {
        result: 1,
        available_point: '-',
        actual_point: '-',
      },
    })
  }

  window.location.href = '/cart'
}

const onClickTip = () => {
  emit('before-click-tip')

  window.location.href = '/cart'
}

const onExpose = async (step?: TagTip.Step) => {
  const { available_point, actual_point } = await cartTagTip.getExposeData()
  const statusMap = {
    default: 0,
    save: 2,
    freeshipping: 1,
    gift: 3,
  }
  if (step) {
    triggerNotice({
      id: 'expose_cart_entrance.comp_header-cart',
      data: {
        status: statusMap[step.type] || 0,
        num: cartNum.value || 0,
        available_point,
        actual_point,
      },
    })
  } else {
    triggerNotice({
      id: 'expose_cart_entrance.comp_header-cart',
      data: {
        status: 4,
        num: cartNum.value || 0,
        available_point,
        actual_point,
      },
    })
  }
}

const init = async () => {
  let step: TagTip.Step | undefined
  if (props.mode === 'incentive') {
    step = await cartTagTip.getCurrentTag()
  }
  onExpose(step)
}

import { useHeader } from './hooks/useHeader'
const { showHeader } = useHeader()

const refHeaderCartSvg = ref(null)
const refCartDiscount = ref(null)
const setTag = async (steps, config) => {
  const { callback } = await showHeader()
  refCartDiscount.value?.setTag(steps, {
    reference: refHeaderCartSvg.value?.$el,
    placement: props.placement,
    offsetY: props.offsetY,
    ...config,
    callback() {
      config?.callback?.()
      setTimeout(() => {
        callback?.()
      }, 1000)
    },
  })
}
const clear = (...args) => {
  refCartDiscount.value?.clear(...args)
}
const cartListener = {
  showtime: (steps, config) => {
    setTag(steps, config)
  },
}
onMounted(() => {
  if (props.mode === 'incentive') {
    registerCart('header-cart', cartListener)
  }

  init()
})
onUnmounted(() => {
  if (props.mode === 'incentive') {
    unregisterCart('header-cart', cartListener)
  }
})

defineExpose({
  setTag,
  clear,
})
</script>

<style lang="less">
.bsc-header-cart {
  position: relative;
  display: inline-flex;
  align-items: center;
  color: #666;
  cursor: pointer;
  &:hover {
    .bsc-cart-num {
      color: #222;
    }
    .bsc-mini-cart {
      display: flex!important;
    }
  }
}
</style>
