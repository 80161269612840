import type { AS_Category } from '../../../types'

function fullZero(num){
  return num < 9 ? `0${num}` : num
}
export function getContentUrl(saInfo, tabNav, needPageId = false){
  let { src_identifier, ici } = saInfo
  const {url} = saInfo
  src_identifier = decodeURIComponent(src_identifier || '')

  if(tabNav && ici){
    const arr = src_identifier.split('`')
    const fcName = `fc=${tabNav.name}`
    const fps = `tab${fullZero(tabNav.index +1) }`
    arr.splice(0,1,fcName)
    const ps = arr[4] || ''
    arr[4] = ps.replace(/tab\d+/, fps)
    ici = ici.replace(/tab\d+/, fps)
    src_identifier = arr.join('`')
  }

  const linkParams = [
    `ici=${ici}`,
    'src_module=topcat',
    `src_identifier=${encodeURIComponent(src_identifier)}`,
    `adp=${saInfo.adp}`,
  ]
  if(needPageId){
    linkParams.push(`src_tab_page_id=${saInfo.src_tab_page_id}`)
  }
  const second_content_list = url?.includes('?') ? `${url}&${linkParams.join('&')}` : `${url}?${linkParams.join('&')}`
  return {second_content_list, src_identifier}
}

function formateData(extraData: any){
  const { tabNav, navCate, isslidebar, isAllHover, thirdItem, fourthItem } = extraData
  const saInfo = (fourthItem || thirdItem || navCate)?.saInfo || {}
  const { second_content_list, src_identifier } = getContentUrl(saInfo, tabNav, true)
  let first_menu_content = isAllHover ? 'All': navCate?.categoryName
  let first_menu_hole = isAllHover ? 0 : navCate?.index + 1
  if(tabNav){
    first_menu_content = tabNav.name
    first_menu_hole = tabNav.index + 1
  }
  return {
    abtest:navCate.abtest,
    first_menu_content,
    first_menu_hole,
    'second_menu_content': navCate.enName || navCate.name,
    'second_menu_hole': navCate.index+1,
    'second_node_id':navCate.categoryId,
    src_identifier,
    second_content_list,
    'is_slidebar':isslidebar,
    'third_menu_content':thirdItem?.categoryName || '',
    'third_menu_hole': !isNaN(thirdItem?.index) ? (thirdItem?.index +1) : '',
    'fourth_menu_content':fourthItem?.categoryName || '',
    'fourth_menu_hole': !isNaN(fourthItem?.index) ? (fourthItem?.index + 1) : '',
    'src_module':'topcat',
  }
}
// 侧边栏组件与导航组件事件上报名称一致，导航页组件多一个abtest属性上报，通过props入参传入
const standard: AS_Category.AnalysisFuncs = {
  // 语种和币种埋点
  'click_change_currency.comp_category': () => {
    return {}
  },
  'click_change_language.comp_category': () => {
    return {}
  },
  'click_navigation.comp_category': () => {
    return {}
  },
  // TODO
  'expose_top_category.comp_category': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'click_top_category.comp_category': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_first_category.comp_category': data => {
    // category与slidecate 共用事件名称,一级导航曝光-批量上报
    const { bindData } = data.source
    const { topNavSa } = data.extraData
    const first_category_list: string[] = []
    const node_ids: string[] = []
    bindData?.length > 0 &&
      bindData.forEach(cate => {
        const { item } = cate
        node_ids.push(item.node_id)
        first_category_list.push(item.first_category_list)
      })
    return {
      ...topNavSa,
      first_category_list: first_category_list.join(','),
      node_id: node_ids.join(','),
    }
  },
  'click_first_category.comp_category': ({ extraData }) => {
    // category与slidecate 共用事件名称,一级导航点击上报
    const { item, topNavSa, turn_page_type } = extraData
    return {
      ...topNavSa,
      ...item,
      turn_page_type,
    }
  },
  'expose_second_category.comp_category': data => {
    // category与slidecate 共用事件名称,二级导航曝光-批量上报
    const { bindData } = data.source
    const { topNavSa, cateType, oneCateContent } = data.extraData
    const first_category = oneCateContent.first_category_list
    const node_ids: string[] = [],
      twoCategoryHeap: string[] = [],
      contentListMap: string[] = []
    bindData?.length > 0 &&
      bindData.forEach(cate => {
        const { item } = cate
        const node_id = item?.metaData?.categoryId || item?.categoryId || ''
        node_ids.push(node_id)
        const contentAnalysis = item.metaData?.contentAnalysis || item.contentAnalysis
        if (contentAnalysis) {
          const { content_list, second_category_list } = contentAnalysis || {}
          twoCategoryHeap.push(second_category_list)
          contentListMap.push(content_list)
        }
      })
    return {
      ...topNavSa,
      first_category,
      src_module: cateType,
      node_id: node_ids?.join(','),
      second_category_list: twoCategoryHeap.join(','),
      src_identifier: contentListMap.join(','),
    }
  },
  'click_second_category.comp_category': ({ extraData }) => {
    // category与slidecate 共用事件名称,二级导航点击上报
    // category多一个abtest属性

    const { item, oneCateContent, topNavSa, cateType } = extraData
    const { first_category_list: first_category, node_id } = oneCateContent
    let second_category_list = '',
      src_identifier = ''
    const contentAnalysis = item.metaData?.contentAnalysis || item.contentAnalysis
    if (contentAnalysis) {
      second_category_list = contentAnalysis.second_category_list
      src_identifier = contentAnalysis.content_list
    }
    // const src_identifier = item?.contentAnalysis?.content_list
    return {
      ...topNavSa,
      first_category,
      node_id,
      src_module: cateType,
      src_identifier,
      second_category_list,
    }
  },
  'expose_turn_page_tip.comp_category': ({ extraData }) => {
    // category 下一页曝光
    const { item, topNavSa } = extraData
    const { top_category } = topNavSa || {}
    const { first_category_list: first_category } = item
    return {
      top_category,
      first_category,
    }
  },
  'click_turn_page_tip.comp_category': (data) => {
    // category 下一页点击
    const { item, topNavSa } = data.extraData
    const { top_category } = topNavSa
    const { first_category_list: first_category } = item
    return {
      top_category,
      first_category,
    }
  },
  'click_top_site_account.comp_category': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  'expose_navigation_menu.comp_laptop': (data) => {
    // const {extraData} = data
    // return formateData(extraData)
    data.source.bindData.forEach(item => {
      const reportData = formateData(item)
      data.report({param:reportData, name:'expose_navigation_menu'})
    })
    return false

    // bindData.forEach(_ => {
    //   const { isslidebar, secondIndex, secondItem, currentOneCategory, thirdIndex, thirdItem, fourthItem, fourthIndex } = _
    //   const abtInfo = abtservice.getUserAbtResultForAnalysis({ posKeys: 'PCAlltabCategory' })
    //   const { src_identifier } = getOptimizeAnalysisParams( {
    //     data: fourthItem || thirdItem || secondItem,
    //     secondCateName: secondItem?.enName || secondItem?.name || '',
    //     secondCateIndex: secondIndex,
    //     thirdCateName: thirdItem?.enName || thirdItem?.name || thirdItem?.parentName || '',
    //     thirdCateIndex: typeof thirdIndex == 'undefined' ? undefined : thirdIndex,
    //     forthCateName: fourthItem?.enName || fourthItem?.categoryName || '',
    //     forthCateIndex: typeof fourthIndex == 'undefined' ? undefined : fourthIndex,
    //     currentOneCategory: currentOneCategory?.name || 'All',
    //     firstCateIndex: typeof currentOneCategory?.index == 'undefined' ? -1 : currentOneCategory?.index
    //   })

    //   const param = {
    //     abtest: abtInfo.sa,
    //     first_menu_content: currentOneCategory?.name || 'All',
    //     first_menu_hole: typeof currentOneCategory?.index == 'undefined' ? 0 : currentOneCategory?.index + 1,
    //     second_menu_content: secondItem?.enName || secondItem?.name || '',
    //     second_menu_hole: typeof secondIndex == 'undefined' ? '' : secondIndex + 1,
    //     second_content_list: getOptimizeLink({
    //       isNew: window?.isNewCccx,
    //       cateLinks: secondItem?.cateLinks,
    //       data: secondItem,
    //       secondCateIndex: secondIndex,
    //       secondCateName: secondItem?.enName || secondItem?.name || '',
    //     }),
    //     second_node_id: secondItem?.navNodeId,
    //     third_menu_content: thirdItem?.enName || thirdItem?.name || thirdItem?.parentName || '',
    //     third_menu_hole: typeof thirdIndex == 'undefined' ? '' : thirdIndex + 1,
    //     fourth_menu_content: fourthItem?.enName || fourthItem?.categoryName || '',
    //     fourth_menu_hole: typeof fourthIndex == 'undefined' ? '' : fourthIndex + 1,
    //     src_module: 'topcat',
    //     src_identifier: decodeURIComponent(src_identifier || ''),
    //     is_slidebar: isslidebar,
    //   }
    //   report({
    //     sa: {
    //       ...sa,
    //       param
    //     }
    //   })
    // })
  },
  'click_navigation_menu.comp_laptop': ({extraData}) => {
    return formateData(extraData)
  },
}

export default standard
