
import { ref, computed, onMounted } from 'vue'
import { checkRedpointExpireTime, setRedpointStatus } from '@shein-aidc/bs-sdk-wish-list-red-point-status'
// 埋点源：
import { useAnalysis } from '../common/analysisSource'
import { stringifyQueryString } from '@shein/common-function'

export function useCommon({
  appConfigs,
  props,
  apis,
}) {
  const redpoint = ref(0)
  const showRedpoint = ref(false)
  const ariaLabel = computed(() => {
    return props.ariaLabel || appConfigs.$language.Save || 'Save'
  })
  const memberId = computed(() => {
    return appConfigs.$getters.memberId
  })
  // 更新红点状态
  const updateRedPointStatus: (forceUpdate?: boolean) => Promise<void> = async (forceUpdate) => {
    try {
      if (!appConfigs.$getters.isLogin && !forceUpdate) {
        // 未登录固定隐藏红点
        showRedpoint.value = false
        redpoint.value = 0
      } else {
        // 已登录，检查storage
        // 1.检查本地缓存的红点已读时间
        const redpointExpire = checkRedpointExpireTime(memberId.value)
        if (!redpointExpire) {
          // 未过期，不展示红点
          showRedpoint.value = false
          redpoint.value = 0
        } else {
          // 2. 过期了，则去重新获取红点状态
          // 2.1 setRedpointExpiredTime 在主站收藏页处理
          requestIdleCallback(async () => {
            try {
            const res = await apis.getRedPointStatus()
            redpoint.value = res.info.result || 0
            showRedpoint.value = redpoint.value != 0
            setRedpointStatus({
                memberId: memberId.value,
                redpointStatus: redpoint.value,
            })
            } catch (err) {
            showRedpoint.value = false
            redpoint.value = 0
            }
          })
        }
      }
    } catch (err) {
      showRedpoint.value = false
      redpoint.value = 0
      console.log('BWishListEntry-updateRedPointStatus:err', err)
    }
  }

  // 更新收藏数量
  const collectCount = ref(0)
  const fetchCollectCount: (forceUpdate?: boolean) => Promise<void> = async (forceUpdate) => {
    console.log('wishListEntry reAddwish')
    try {
      if (!appConfigs.$getters.isLogin && !forceUpdate) {
        // 未登录不请求
        collectCount.value = 0
      } else {
        requestIdleCallback(async () => {
          try {
          const res = await apis.getUserCollectCount()
          collectCount.value = Number(res.info.count) || 0
          } catch (err) {
            collectCount.value = 0
          }
        })
      }
    } catch (err) {
      collectCount.value = 0
      console.log('BWishListEntry-fetchCollectCount:err', err)
    }
  }

  // 手动修改收藏商品
  const manualUpdateCollectCount: (count: number) => void = (count) => {
    if (!isNaN(count)) {
      collectCount.value += Number(count)
    }
    if (collectCount.value < 0) {
      collectCount.value = 0
    }
  }

  return {
    redpoint,
    ariaLabel,
    showRedpoint,
    collectCount,
    fetchCollectCount,
    updateRedPointStatus,
    manualUpdateCollectCount,
  }
}

// 埋点相关
export function useReportCommon({
  redpoint,
  props,
}) {
  const { vTap, vExpose, getExposeInstance } = useAnalysis(props.analysisSource)
  const daEventExpose = ref<any>({})
  onMounted(() => {
    daEventExpose.value = getExposeInstance()
  })
  const resetExpose = function() {
    console.log('test：resetExpose')
    try {
      daEventExpose.value?.resetAll?.('comp_wish-list-entry')
    } catch (err) {
      console.log('BWishListEntry-resetExpose-error', err)
    }
  }

  const { triggerNotice } = useAnalysis(props.analysisSource)
  const exposeReport: () => void = () => {
    const extraData = {
      is_red: redpoint.value,
    }
    triggerNotice({
      id: 'expose_wishlist_enter.comp_wish-list-entry',
      data: extraData,
    })
  }

  const clickReport: () => void = () => {
    const extraData = {
      is_red: redpoint.value,
    }
    triggerNotice({
      id: 'click_gotowishlist.comp_wish-list-entry',
      data: extraData,
    })
  }

  return {
    vTap,
    vExpose,
    resetExpose,
    exposeReport,
    clickReport,
  }
}

// pwa vue2 vue2相同部分
export function useMobileCommon({
  appConfigs,
  props,
  clickReport,
}: {
  appConfigs: any;
  props: any;
  clickReport?: any;
}) {
  const memberId = computed(() => {
    return appConfigs.$getters.memberId
  })
  const { $envs } = appConfigs
  const goWishlist = () => {
    clickReport?.()
    const expirationTime = Number(
      localStorage.getItem(`wish_shein_club_key_${memberId.value}`),
    )
    let cpm = ''
    if (expirationTime && Date.now() < expirationTime) {
      cpm = '1' // 还没有过期， 添加标识
    }
    const queryObj = {
      pf: 'topbar',
      cpm,
      ...(props.routeQuery && typeof props.routeQuery === 'object' ? props.routeQuery : null),
    }
    location.href = `${location.origin}${$envs.langPath}/wishlist?${stringifyQueryString({queryObj})}`
  }
  return {
    goWishlist,
  }
}
