<template>
  <transition
    :name="transitionName"
    @after-enter="afterEnter"
  >
    <span
      v-if="show"
      ref="refBCartTip"
      class="bsc-cart-tip"
      :class="classname"
      :style="style"
      @click="onClick"
    >
      <span class="bsc-cart-tip__content">
        <span class="bsc-cart-tip__icon"></span>
        <span
          class="bsc-cart-tip__text"
          v-html="text"
        ></span>
      </span>
      <span
        class="bsc-cart-tip__close"
        @click.stop="onClose"
      >
        <Icon
          name="sui_icon_closed_14px_1"
          size="14px"
          color="#222222"
        />
      </span>
    </span>
  </transition>
</template>

<script name="BCartTip" setup lang="ts">
import { ref, computed, nextTick } from 'vue'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { Icon } from '@shein-aidc/icon-vue3'
import { C_HeaderCart } from '../../../types'
import { computePosition, getSide } from '../../../common/popup/computePosition'

import type { Placement, TagTip } from '@shein-aidc/bs-sdk-cart-tag-tip'

const { $envs } = useAppConfigs()

const emit = defineEmits(['click', 'expose', 'after-enter'])

const style = ref({})

const classname = computed(() => {
  return [
    `bsc-cart-tip__${_placement.value}`,
    {
      'bsc-cart-tip__css-right': $envs.cssRight,
      'bsc-cart-tip--freeshipping': type.value === 'freeshipping',
    },
  ]
})

const show = ref(false)
const transitionName = ref('')
const text = ref('')
const type = ref('')

const _placement = ref<Placement>('bottom-end')

let callback: TagTip.Config['callback']

const open = async (step: TagTip.Step, config: C_HeaderCart.TipOpenConfig) => {
  callback = config.callback

  _placement.value = config.placement || _placement.value

  type.value = step.type
  text.value = step.tips as string
  transitionName.value = `bsc-cart-tip__jump-${getSide(_placement.value)}`

  show.value = true

  await nextTick()

  setStyle(config)

  emit('expose')
}

const refBCartTip = ref<HTMLElement>()
const setStyle = ({ reference, placement, offsetX, offsetY }: C_HeaderCart.TipOpenConfig) => {
  if (!refBCartTip.value || !reference) return
  const place = placement || _placement.value
  const position = computePosition(reference, refBCartTip.value, { placement: place, offsetX: offsetX ?? 16, offsetY: offsetY ?? 18, isRTL: $envs.cssRight })
  style.value = {
    top: `${position.y}px`,
    left: `${position.x}px`,
  }
}

const afterEnter = () => {
  transitionName.value = ''
  type.value = ''
  text.value = ''

  callback?.()
  callback = undefined

  show.value = false

  emit('after-enter')
}

const onClick = () => {
  emit('click')
}

const onClose = () => {
  // TODO
  show.value = false
}

const clear = () => {
  afterEnter()
}

defineExpose({
  open,
  clear,
})
</script>

<style lang="less">
// @translateY: calc(100% + 15px);
@translateY: 0px;
@space: 12px;
@jump: 10px;

@keyframes bsc-cart-tip__jump-top {
  0% {
    opacity: 0;
  }
  12% {
    opacity: 0;
    transform: translateY(calc(@translateY - @space - @jump));
  }
  31.75% {
    opacity: 1;
    transform: translateY(calc(@translateY - @space));
  }
  51.5% {
    transform: translateY(calc(@translateY - @space - @jump));
  }
  71.25% {
    transform: translateY(calc(@translateY - @space));
  }
  91% {
    opacity: 1;
    transform: translateY(calc(@translateY - @space - @jump));
  }
  100% {
    opacity: 0;
    transform: translateY(calc(@translateY));
  }
}

@keyframes bsc-cart-tip__jump-bottom {
  0% {
    opacity: 0;
  }
  12% {
    opacity: 0;
    transform: translateY(calc(@translateY + @space + @jump));
  }
  31.75% {
    opacity: 1;
    transform: translateY(calc(@translateY + @space));
  }
  51.5% {
    transform: translateY(calc(@translateY + @space + @jump));
  }
  71.25% {
    transform: translateY(calc(@translateY + @space));
  }
  91% {
    opacity: 1;
    transform: translateY(calc(@translateY + @space + @jump));
  }
  100% {
    opacity: 0;
    transform: translateY(calc(@translateY));
  }
}

.bsc-cart-tip__jump-top-enter-active {
  animation: bsc-cart-tip__jump-top 2.2s;
}

.bsc-cart-tip__jump-bottom-enter-active {
  animation: bsc-cart-tip__jump-bottom 2.2s;
}

.bsc-cart-tip {
  position: fixed;
  opacity: 0;
  display: flex;
  align-items: center;
  width: max-content;
  max-width: 362px;
  font-size: 14px;
  font-weight: 700;
  color: #fa6338;
  background-color: #FFF6F3;
  border-radius: 2px;
  box-shadow: 0 2px 12px 0 rgba(#000, .16);
  z-index: 600;

  &--freeshipping {
    color: #3CBD45;
    background-color: #F5FCFB;
    &::before {
      background-color: #F5FCFB !important;
    }
  }

  &__content {
    display: flex;
    padding: 16px 0 16px 16px;
    cursor: pointer;
  }

  &__icon {
    display: inline-block;
    flex-shrink: 0;
    margin-right: 4px;
    width: 16px;
    height: 16px;
    background-image: url("https://img.ltwebstatic.com/images3_ccc/2024/09/11/ca/1726037063f31070ce71eb1b2bd9c8336ceaec9e4e.png");
    background-size: cover;
  }

  &__text {
    display: -webkit-box;
    line-height: 16px;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }

  &__close {
    display: inline-block;
    padding: 16px;
    cursor: pointer;
  }

  &::before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: #FFF6F3;
    box-shadow: -2px -2px 3px 0 rgba(#000, .05);
  }

  &__top::before {
    bottom: 0;
    left: 50%;
    margin-left: -16px;
    transform-origin: bottom left;
    transform: rotateZ(45deg);
  }
  &__top-start::before {
    bottom: 0;
    left: 16px;
    transform-origin: bottom left;
    transform: rotateZ(45deg);
  }
  &__top-end::before {
    bottom: 0;
    right: 16px;
    transform-origin: bottom right;
    transform: rotateZ(-45deg);
  }

  &__bottom::before {
    top: 0;
    left: 50%;
    margin-left: -16px;
    transform-origin: top left;
    transform: rotateZ(-45deg);
  }
  &__bottom-start::before {
    top: 0;
    left: 16px;
    transform-origin: top left;
    transform: rotateZ(-45deg);
  }
  &__bottom-end::before {
    top: 0;
    right: 16px;
    transform-origin: top right;
    transform: rotateZ(45deg);
  }
}
</style>
